<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Country*</label>
        <country-component
          @input="inputCountry"
          :name="country.name"
          :id="country.id"
        ></country-component>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Province*</label>
        <province-component
          @input="inputProvince"
          :name="province.name"
          :id="province.id"
          :countryId="country.id"
        ></province-component>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">City*</label>
        <city-component
          @input="inputCity"
          :id="city.id"
          :name="city.name"
          :shortName="city.shortName"
          :provinceId="province.id"
        ></city-component>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">District*</label>
        <district-component
          @input="inputDistrict"
          :id="district.id"
          :code="district.code"
          :name="district.name"
          :cityId="city.id"
          :cityName="city.name"
        ></district-component>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Subdistrict*</label>
        <subdistrict-component
          @input="inputSubistrict"
          :id="subdistrict.id"
          :name="subdistrict.name"
          :code="subdistrict.code"
          :postalCode="subdistrict.postalCode"
          :cityId="city.id"
          :cityName="city.name"
          :districtId="district.id"
          :districtName="district.name"
        ></subdistrict-component>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Address*"
          v-model="mutableData.address"
          v-validate="'required'"
          name="Address"
        />
        <span class="text-danger text-sm" v-show="errors.has('Address')">{{
          errors.first("Address")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Postal Code*"
          v-model="mutableData.postalCode"
          v-validate="'numeric'"
          name="PostalCode"
        />
        <span class="text-danger text-sm" v-show="errors.has('PostalCode')">{{
          errors.first("PostalCode")
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import CountryComponent from "@/components/master/Country";
import ProvinceComponent from "@/components/master/Province";
import CityComponent from "@/components/master/City";
import DistrictComponent from "@/components/master/District";
import SubdistrictComponent from "@/components/master/Subdistrict";
export default {
  components: {
    CountryComponent,
    ProvinceComponent,
    CityComponent,
    DistrictComponent,
    SubdistrictComponent,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      mutableData: {
        address: "",
        postalCode: "",
      },
      country: {
        id: this.data.countryID,
        name: "",
      },
      province: {
        id: this.data.provinceID,
        countryId: this.data.countryID,
        name: "",
      },
      city: {
        id: this.data.cityID,
        provinceId: this.data.provinceID,
        name: "",
        shortName: "",
        transportZone: "",
      },
      district: {
        id: this.data.districtID,
        cityId: this.data.cityID,
        code: "",
        name: "",
      },
      subdistrict: {
        id: this.data.subdistrictID,
        cityId: this.data.cityID,
        districId: this.data.districtID,
        code: "",
        name: "",
        postalCode: "",
      },
    };
  },
  methods: {
    inputCountry(value) {
      this.country.id = value.id;
      this.country.name = value.name;
      this.emitData();
    },
    inputProvince(value) {
      this.province.id = value.id;
      this.province.countryId = value.country_id;
      this.province.name = value.name;
      this.emitData();
      // if (this.country.id != value.country_id) {
      //   this.country.id = value.country_id;
      // }
    },
    inputCity(value) {
      this.city.id = value.id;
      this.city.provinceId = value.province_id;
      this.city.name = value.name;
      this.city.shortName = value.short_name;
      this.city.transportZone = value.transport_zone;
      this.emitData();
      // if (this.province.id != value.province_id) {
      //   this.province.id = value.province_id;
      // }
    },
    inputDistrict(value) {
      this.district.id = value.id;
      this.district.cityId = value.city_id;
      this.district.name = value.name;
      this.district.code = value.code;
      this.emitData();
      // if (this.city.id != value.city_id) {
      //   this.city.id = value.city_id;
      // }
    },
    inputSubistrict(value) {
      this.subdistrict.id = value.id;
      this.subdistrict.cityId = value.city_id;
      this.subdistrict.districId = value.district_id;
      this.subdistrict.name = value.name;
      this.subdistrict.code = value.code;
      this.subdistrict.postalCode = value.postal_code;
      this.mutableData.postalCode = value.postal_code;
      this.emitData();
    },
    setMutableData() {
      this.mutableData.address = this.data.address;
      if(this.data.showWarningAddress){
        this.errors.add({
          field: "Address",
          msg: "The Address field is required",
        });
      }
      this.mutableData.postalCode = this.data.postalCode;
      this.mutableData.address = this.data.address;
      this.country.name = this.data.countryName;
      this.country.id = this.data.countryID;

      this.province.name = this.data.provinceName;
      this.province.id = this.data.provinceID;
      this.province.countryId = this.data.countryID;

      this.city.name = this.data.cityName;
      this.city.id = this.data.cityID;
      this.city.provinceId = this.data.provinceID;

      this.district.name = this.data.districtName;
      this.district.id = this.data.districtID;
      this.district.cityId = this.data.cityID;

      this.subdistrict.name = this.data.subdistrictName;
      this.subdistrict.id = this.data.subdistrictID;
      this.subdistrict.districId = this.data.districtID;
      this.subdistrict.cityId = this.data.cityID;
    },
    emitData() {
      this.$emit("data", {
        address: this.mutableData.address,
        postalCode: this.mutableData.postalCode,
        countryName: this.country.name,
        provinceName: this.province.name,
        cityName: this.city.name,
        transportZone: this.city.transportZone,
        districtName: this.district.name,
        subdistrictName: this.subdistrict.name,
        countryID: (this.data.countryID ? ((this.data.countryID != this.country.id) ? this.country.id : this.data.countryID) : this.country.id),
        provinceID: (this.data.provinceID ? ((this.data.provinceID != this.province.id) ? this.province.id : this.data.provinceID) : this.province.id),
        cityID: (this.data.cityID ? ((this.data.cityID != this.city.id) ? this.city.id : this.data.cityID) : this.city.id),
        districtID: (this.data.districtID ? ((this.data.districtID != this.district.id) ? this.district.id : this.data.districtID) : this.district.id),
        subdistrictID: (this.data.subdistrictID ? ((this.data.subdistrictID != this.subdistrict.id) ? this.subdistrict.id : this.data.subdistrictID) : this.subdistrict.id),
      });
    },
  },
  mounted() {
    this.setMutableData();
  },
  watch: {
    // mutableData: {
    //   deep: true,
    //   handler() {
    //     this.emitData();
    //   },
    // },
    "country.name": {
      deep: true,
      handler() {
        this.emitData();
      },
    },
    "province.name": {
      deep: true,
      handler() {
        this.emitData();
      },
    },
    "city.name": {
      deep: true,
      handler() {
        this.emitData();
      },
    },
    "district.name": {
      deep: true,
      handler() {
        this.emitData();
      },
    },
    "subdistrict.name": {
      deep: true,
      handler() {
        this.emitData();
      },
    },
    "mutableData.address": {
      deep: true,
      handler() {
        this.emitData();
      },
    },
    data: {
      deep: true,
      handler(val) {
        this.setMutableData();
      },
    },
  },
};
</script>